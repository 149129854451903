import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { down, up } from "styled-breakpoints"
//DATA
import { info } from "../assets/data/kontakt"
import film from "../assets/video/film.mp4"
//STYLES
import { H1, H2, H3, H4, H5, H6, Text, LabelBW } from "../utils/typo"
import {
  Div,
  Section2,
  ContainerMob,
  CardWrapperFull,
  CardWrapperFull2,
  ContainerThin,
} from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/Seo"
import BanerVideo from "../components/baner/BanerVideo"
import Button from "../components/button/Button"
import SeoImage from "../components/seo/SeoImage"
import Video from "../components/video/Video"
import { theme } from "../utils/theme"

const Baner = styled.div`
  position: absolute;
  width: 100%;
  max-width: 2560px;
  height: auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  > div:nth-of-type(2) {
    display: none;
  }

  ${down("xs")} {
    > div:nth-of-type(1) {
      display: none;
    }
    > div:nth-of-type(2) {
      display: block;
    }
  }

  :before {
    content: "";
    background: rgb(0, 46, 12);
    background: linear-gradient(
      180deg,
      rgba(0, 46, 12, 0.85) 0%,
      rgba(24, 77, 0, 0.65) 13%,
      rgba(96, 143, 11, 0.65) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 2;
  }
`

const Card = styled.div`
  padding: 1rem;
  background-color: ${props => props.bgColor || "white"};
  min-height: 100px;
  outline: ${props =>
    props.bgColor ? "1px solid transparent" : "1px solid lightgray"};
  display: flex;
  flex-direction: column;
  h4 {
    text-align: center;
    line-height: 1.1;
  }

  > div:nth-of-type(1) {
    margin: 0rem 1.3rem 1rem 1.3rem;
    ${down("md")} {
      margin: 0rem 2.5rem 1rem 2.5rem;
    }
    ${down("sm")} {
      margin: 0rem 3rem 1rem 3rem;
    }
    ${down("xs")} {
      margin: 0rem 2rem 1rem 2rem;
    }
  }
`

export default function Oferta() {
  return (
    <div>
      <Seo title="Rotkegel - producent roślin najwyższej jakości" slug="/" />
      <SeoImage />

      <Baner>
        <StaticImage
          src="../assets/images/6.jpg"
          alt="Obrazy w ramkach - idealny upominek na dzieńkobiet"
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
          aspectRatio={3.5}
        />
        <StaticImage
          src="../assets/images/6.jpg"
          alt="Obrazy w ramkach - idealny upominek na dzieńkobiet"
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
          aspectRatio={1.3}
        />
      </Baner>
      <ContainerMob mt="4rem" mtMobile="2rem">
        <H1 colorFont="white" textAlign="center">
          Rośliny dla Twojego ogrodu
        </H1>
      </ContainerMob>
      <Section2 pt="6">
        <ContainerMob>
          <CardWrapperFull xl="8" l="8" m="4" s="3">
            <Card>
              <StaticImage
                src={`../assets/images/lisciaste.png`}
                alt="Sprzedajemy"
                layout="fullWidth"
                objectFit="cover"
                placeholder="blurred"
                aspectRatio={1}
              />
              <H4>Drzewa iglaste</H4>
            </Card>
            <Card>
              <StaticImage
                src={`../assets/images/iglaste.png`}
                alt="Sprzedajemy"
                layout="fullWidth"
                objectFit="cover"
                placeholder="blurred"
                aspectRatio={1}
              />
              <H4>Drzewa liściaste</H4>
            </Card>
            <Card>
              <StaticImage
                src={`../assets/images/owocowe.png`}
                alt="Sprzedajemy"
                layout="fullWidth"
                objectFit="cover"
                placeholder="blurred"
                aspectRatio={1}
              />
              <H4>Drzewa owocowe</H4>
            </Card>
            <Card>
              <StaticImage
                src={`../assets/images/zywoploty.png`}
                alt="Sprzedajemy"
                layout="fullWidth"
                objectFit="cover"
                placeholder="blurred"
                aspectRatio={1}
              />
              <H4>Żywopłoty</H4>
            </Card>
            <Card>
              <StaticImage
                src={`../assets/images/krzewy.png`}
                alt="Sprzedajemy"
                layout="fullWidth"
                objectFit="cover"
                placeholder="blurred"
                aspectRatio={1}
              />
              <H4>Krzewy</H4>
            </Card>
            <Card>
              <StaticImage
                src={`../assets/images/krzewyowocowe.png`}
                alt="Sprzedajemy"
                layout="fullWidth"
                objectFit="cover"
                placeholder="blurred"
                aspectRatio={1}
              />
              <H4>Krzewy owocowe</H4>
            </Card>
            <Card>
              <StaticImage
                src={`../assets/images/byliny.png`}
                alt="Sprzedajemy"
                layout="fullWidth"
                objectFit="cover"
                placeholder="blurred"
                aspectRatio={1}
              />
              <H4>Byliny</H4>
            </Card>
            <Card>
              <StaticImage
                src={`../assets/images/kwiaty.png`}
                alt="Sprzedajemy"
                layout="fullWidth"
                objectFit="cover"
                placeholder="blurred"
                aspectRatio={1}
              />
              <H4>Kwiaty</H4>
            </Card>
          </CardWrapperFull>
        </ContainerMob>
      </Section2>
    </div>
  )
}
