import React from "react"
import styled from "styled-components"
import { down } from "styled-breakpoints"
//STYLES
import { H1, Text } from "../../utils/typo"
import { CardWrapper } from "../../utils/utils"
//COMPONENTS
import Button from "../button/Button"
import Video from "../video/Video"
import Baner from "../baner/BanerVideoMobile"

const BanerWrapper = styled.div`
  background-color: lightgray;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;

  ${down("sm")} {
    display: none;
  }
`

const InfoWrapper = styled(CardWrapper)`
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: center;

  div {
    display: flex;
    flex-direction: column;
  }
`

const Ratio = styled.canvas`
  width: 100%;
  margin: 0;
  display: block;
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
`

class BanerVideo extends React.Component {
  state = { clicked: false }

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked })
  }

  render() {
    return (
      <>
        <BanerWrapper>
          <Video
            videoSrcURL={this.props.videoSrcURL}
            videoTitle={this.props.videoTitle}
          />

          <Ratio width="16" height="9" />
          <Wrapper>
            <InfoWrapper xl="2" l="2" m="2" s="1">
              <div>
                <H1 colorFont="white">{this.props.heading}</H1>
                <Text colorFont="white">{this.props.intro}</Text>
                <Button link={this.props.link || "/"} col="red">
                  Dowiedz się więcej o nas
                </Button>
              </div>
            </InfoWrapper>
            {this.props.children}
          </Wrapper>
        </BanerWrapper>
        <Baner
          heading={this.props.heading}
          intro={this.props.intro}
          link={this.props.link || "/"}
        ></Baner>
      </>
    )
  }
}
export default BanerVideo
