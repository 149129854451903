import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { down, up } from 'styled-breakpoints'

import { H1, Text } from '../../utils/typo'
import { CardWrapper } from '../../utils/utils'
import Button from '../button/Button'
import { theme } from '../../utils/theme'

const Image = styled.div`
    width: 100%;
    height: auto;
    position: absolute;

    ::before {
        content: '';
        position: absolute;
        display: block;
        //background-color: ${({ theme }) => theme.color.base1};
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        opacity: .65; 
    }

    ::after {
        content: '';
        position: absolute;
        display: block;
        background-color: ${({ theme }) => theme.color.prim1};
        top: 0;
        left: 0;
        bottom: 0;
        width: 50%;
        opacity: .8; 
    }

    > div {
        width: 100%;
        height: auto;
    }

    ${down("sm")} {
      display: none;
    }
`;

const ImageMobile = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;

    > div {
        width: 100%;
        height: 100%;
    }
    ${up("md")} {
      display: none;
    }
`;

const BanerWrapper = styled.div`
    background-color: lightgray;
    position: relative;
    display: flex;
    flex-direction: column;

    ${up("md")} {
      display: none;
    }
`;

const InfoWrapper = styled(CardWrapper)`
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    width: 100%;
    height: 100%;
    align-content: center;
    z-index: 1;

    div {
        display: flex;
        flex-direction: column;
    }

    ${down("sm")} {
      padding: 1rem;

      ::before {
        content: '';
        position: absolute;
        display: block;
        background-color: ${({ theme }) => theme.color.prim1};
        top: 100px;
        left: 0;
        bottom: 100px;
        width: 100%;
        opacity: .8; 
        z-index: -1;
        }
    } 

    ${down("xs")} {
      ::before {
        content: '';
        position: absolute;
        display: block;
        background-color: ${({ theme }) => theme.color.prim1};
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        opacity: .8; 
        z-index: -1;
    }
    }
`;

const Ratio = styled.canvas`
    width: 100%;
    margin: 0;
    display: block;
    
    ${down("sm")} {
      display: none;
    }
`;

const RatioMobile = styled.canvas`
    width: 100%;
    height: 100vh;
    margin: 0;
    display: block;

    ${up("md")} {
      display: none;
    }
`;

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
`;


class Baner extends React.Component {

    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {


        return (
            <BanerWrapper>
                <Image>
                    <StaticImage
                        src='../../assets/images/main_img.jpg'
                        alt='Baner foto'
                        placeholder="blurred"
                        aspectRatio={16 / 9}
                        layout='fullWidth'
                    />
                </Image>

                <ImageMobile>
                    <StaticImage
                        src='../../assets/images/main_img.jpg'
                        alt='Baner foto'
                        placeholder="blurred"
                        layout='fullWidth'
                    />
                </ImageMobile>


                <Ratio width="16" height="9" />
                <RatioMobile />

                <Wrapper>
                    <InfoWrapper xl='2' l='2' m='2' s='1' xs='1'>
                        <div>
                            <H1 colorFont='white'>
                                {this.props.heading}
                            </H1>
                            <Text
                                mt='2rem'
                                colorFont='white'>
                                {this.props.intro}
                            </Text>
                            <Button
                                link={this.props.link || '/page1'}
                                color={theme.color.base1}>
                                Dowiedz się więcej o nas
                            </Button>
                        </div>
                    </InfoWrapper>
                    {this.props.children}
                </Wrapper>
            </BanerWrapper>
        )
    }
}
export default Baner